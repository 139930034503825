import _ from "core-cmp/lodash";
import Rights from "core-uda/Rights";

const STD_TO_THM = {
  "alertDefinition.sharedValue":    "data.alertDefinition.sharedValue LR",
  "alertDefinition.activatedValue": "data.alertDefinition.activatedValue LR",
  "alert.kinds":                    "alertTypes LR",
  "beaconEvent.kinds":              "beaconEventTypes LR",
  "alertDefinition.kinds":          "alertTypes LR",
  "position.clusterLimit":          "data.position.clusterLimit LR",
  "position.maxResult":             "data.position.maxResult LR",
  "positionsCriteria.sources":      "data.positionsCriteria.sources A",
  "positionsCriteria.natures":      "data.positionsCriteria.natures A",
  "map.forceLoadAll":               "loadAll A",
  "user.domain":                    "webAppDomain.lexicon LR",
  "radar.command.url":              "maestro.command.url LR",
  "loadAll":                        "umv.loadAll A",
  "charts":                         "umv.charts A",
  "afterSearch.zoomAndCenter":      "afterSearch.zoomAndCenter A",
  "afterSearch.showTrajectories":   "afterSearch.showTrajectories A",
  "center.customer.project":        "center.customer.project R",
  "userInformation":                "userInformation A",
  "position.aisTraffic":            "aisTraffic LRB",
  "position.aisFields":             "showAisFields LRB",
  "metoc.authorized":               "umv.metoc LRB",
  "mobile.trajectoriesTab":         "trajectoriesTab LRB",
  "mobile.allowBeaconLink":         "data.mobile.allowBeaconLink LR",
  "mobile.additionalServices":      "center.showAdditionalServices LR",
  "sarSRUnits":                     "sarSRUnits A"
};

const STATIC_RIGHTS = {
  "locales":                             "es-CL,es-EC,el,en,es,fr,ro,ru,zh",
  "lexicons":                            ["tracking", "fish", "fishing", "animal"],
  "data.chart.authorized":               true,
  "data.chart.edit":                     true,
  "data.userObject.authorized":          true,
  "data.userObject.edit":                true,
  "data.geoAnnotation.authorized":       true, // TO REMOVE WHEN UDA READY
  "data.positionsCriteria.loc1Message":  false, // For cata (Argos) only
  "navEffects":                          "airbnb,moveleft,moveright,movedown,rotateleft,rotatetop,laydown",
  "dateFormatList":                      ["dd/MM/yyyy", "dd-MM-yyyy", "dd.MM.yyyy", "MM/dd/yyyy", "MM-dd-yyyy", "MM.dd.yyyy", "yyyy/MM/dd", "yyyy-MM-dd", "yyyy.MM.dd"],
  "radar.scene.geoImage.maxResolution":  2500,
  "radar.echo.geoImage.maxResolution":   105,
  "rendering.path":                      true,
  "flagOnPosition.field.masked":         ["versionNum", "mobileId", "modificationDateMillis", "locationDateMillis", "id", "beaconId", "activeBeaconId", "locDateMillis", "reference", "creationDateMillis", "supplierDateMillis", "receivedDateMillis", "providerReceivedDelay", "receivedSavedDelay"],
  "shape.innerRingAuthorized":           false,
  "mobile.idFields":                     "name,imo,mmsi,activeBeaconRef,callSign,registry,ref,internalRef",
  "data.alerts.notification.authorized": true,
  "data.mobidriftObject.authorized":     true,
  "metoc.authorized.depths":             [0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 350, 400, 450, 500, 550, 600, 700, 800, 900, 1000],
  "data.zone.kindGeometryTypes":         {
    "annotation":  ["Point"],
    "port":        ["Circle", "Polygon", "MultiPolygon"],
    "official":    ["LineString", "MultiLineString", "Circle", "Rectangle", "Polygon", "MultiPolygon", "GeometryCollection"],
    "workstation": ["Point"],
    "itinerary":   ["LineString"],
    "landmark":    ["Point"],
    "user":        ["LineString", "MultiLineString", "Circle", "Rectangle", "Polygon", "MultiPolygon", "GeometryCollection"]
  },
  "minCharSearch":                       3,
  "excludingMobile":                     true,
  "data.reportTemplate.authorized": true,
  "data.report.authorized": true,
  "data.publicImage.authorized": true
};

/**
 * Classe abstraite de gestion des droits.
 * Chaque application doit implémenter la méthode computeRight.
 */
let ThmRights = Rights.extend({

  /**
   * Constructeur.
   * @param rights
   */
  init: function (rights) {
    let me = this;
    me.rights = rights
  },

  /**
   * Retourne le droits pour la clé spécifiée.
   * @param {string} key : clé.
   * @returns {*}
   */
  computeRight: function (key) {
    let me        = this,
        customKey = STD_TO_THM[key];

    // We test for undefined because the right could be set to false
    if (!_.isUndefined(STATIC_RIGHTS[customKey || key])) {
      return STATIC_RIGHTS[customKey || key];
    }

    // Si la clé normalisée (en argument) est supportée par umv, c'est parti !
    if (!customKey) {
      return me.loadRight(key);
    }

    customKey = customKey.split(" ");

    // Si UMV ne supporte pas la clé normalisée (en argument), on utilise son équivalent UMV
    switch (customKey[1]) {
      case "R" :
        return me.getRight(customKey[0]);
      case "LR" :
        return me.loadRight(customKey[0]);
      case "LRB" :
        return me.loadRight(customKey[0], false);
      case "A" :
        return me.isAuthorized(customKey[0]);
    }

    return null;
  },

  /**
   * Retourne la valeur du droit.
   * @param {String} key - identifiant du droit.
   * @retrurn valeur du droit.
   */
  getRight: function (key) {
    let me     = this,
        cursor = me.rights;

    _.each(key.split('.'), function (part) {
      cursor = _.isNil(cursor) ? undefined : cursor[part];
    });

    if ("true" === cursor) {
      return true;
    }
    if ("false" === cursor) {
      return false;
    }
    return cursor;
  },

  /**
   * Recherche le droits aux différents niveau (Role, centre, customer).
   */
  loadRight: function (key, defaultValue) {
    let me                 = this,
        customerRight      = me.getRight("customer." + key), // Read property at customer level
        roleDefaultRight   = me.getRight("role." + key), // Read property at role level
        roleUMVRight       = me.getRight("role.umv." + key), // FIXME : To remove to simplify thm rights (JIRA XXX)
        roleRight          = roleUMVRight,
        centerDefaultRight = me.getRight("center." + key), // Read property at center level
        centerUMVRight     = me.getRight("center.map." + key), // FIXME : To remove to simplify thm rights (JIRA XXX)
        centerRight        = centerUMVRight,
        right;

    // Si aucun droit pour l'application umv dans le role, on prend les droits communs à toutes les applications pour le role.
    if (_.isUndefined(roleUMVRight)) {
      roleRight = roleDefaultRight;
    }

    // Si aucun droit pour l'application umv au niveau centre, on prend les droits communs à toutes les applications pour le centre.
    if (_.isUndefined(centerUMVRight)) {
      centerRight = centerDefaultRight;
    }

    // la plupart du temps la valeur n'est positionnée que sur l'un des 3 niveaux.
    // Si elle est positionné sur plusieurs niveau :
    // - Pour les booléen on fait un ET logique sur les valeur définies.
    // - Pour les autre type, on prend la valeur du plus bas niveau (customer / role / center)
    if (!_.isUndefined(customerRight)) {
      right = customerRight;
    }
    if (!_.isUndefined(roleRight) && (right === true || _.isUndefined(right))) {
      right = roleRight;

      // Si le droit se résume à un objet avec une unique propriété "authorized" on retourne la valeur de authorized
      if (right && _.size(right) === 1 && !_.isNil(right.authorized)) {
        right = right.authorized;
      }
    }
    if (!_.isUndefined(centerRight) && (right === true || (right && right.authorized === true) || _.isUndefined(right))) {
      right = centerRight;
    }

    return _.isNil(right) && !_.isNil(defaultValue) ? defaultValue : right;
  },

  /**
   * Cette fonction prends en argument un essemble de contraintes.
   * Elle retourne true si toutes ces contraintent valent true.
   * @param {String[]} noms des contraintes
   * @return true si toutes les contraintes valent true, false sinon
   */
  isAuthorized: function (key) {
    let me    = this,
        right = me.loadRight(key);

    return right && (right === true || right.authorized === true);
  }

});

export default ThmRights;
