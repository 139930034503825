import _ from "core-cmp/lodash";
import { APP, RESOURCE_CLASS } from "core-uda/model/Resource";
import { RIGHTS, DATA_AUTHORIZED } from "core-uda/Rights";
import { THM_ALERT_UI } from "thm/ui/common/alert/ThmAlertUI";
import { ALERT_UI } from "core-alert/ui/alert/AlertUI";
import BooleanCriteria from "core-criteria/boolean/BooleanCriteria";
import CheckboxesCriteria from "core-criteria/listCheckbox/CheckboxesCriteria";

import DateRequestPage from "core-uda/ui/defaultRequest/DateRequestPage";
import { SAR_ALERT_STATUS_CRITERIA, SAR_ALERT_ACK_CRITERIA } from "core-sar/ui/sarRequest/SarRequestPage";
import { DEFAULT_LAST_POSITION_CRITERIA } from "core-criteria/lastPosition/LastPositionCriteria";
import { DEFAULT_ZONE_CRITERIA } from "core-criteria/zone/ZoneCriteria";
import { DEFAULT_SPEED_CRITERIA } from "core-criteria/speed/SpeedCriteria";
import { DEFAULT_NATURE_CRITERIA } from "core-criteria/nature/NatureCriteria";
import { DEFAULT_SOURCE_CRITERIA } from "core-criteria/source/SourceCriteria";
import { DEFAULT_DATE_CRITERIA } from "core-criteria/date/DateCriteria";

import ThmSelectByCriteria from "thm/ui/common/criteria/ThmSelectByCriteria";

import CheckboxActionTpl from "core-uda/ui/defaultRequest/actions/checkboxActionTpl.stache";
import DeactivableCheckboxActionTpl from "thm/ui/actions/DeactivableCheckBox.stache";
import HcomboActionTpl from "core-uda/ui/defaultRequest/actions/hcomboActionTpl.stache";
import Toastr from "core-cmp/Toastr";

let ThmRequestPage = DateRequestPage.extend({}, {
  id: "thmRequestPage",
  i18nPrefix: [
    "core-uda.ui.defaultRequest.DefaultRequestPage.",
    "thm.ui.umv.requestPage.ThmRequestPage.",
    "core-alert.service.info.AlertHandler.",
    "uda.alert.field.validationStatus.",
    "core-sar.ui.sarRequest.SarRequestPage."],

  bindEvents: function () {
    let me = this;

    APP().on('deactivateHistoricDetails', (ev, status) => {

      if (status) {
        me.viewModel.attr('loadingFormModel.historicVesselDetails', false);
      }

      me.setDetailsValue(status);
    });

    me._super();
  },

  setDetailsValue: function (value) {
    let me = this;

    _.find(me.viewModel.attr("loadActions"), x => x.id === 'historicVesselDetails').attr('active', !value);
  },

  /**
   * @override
   */
  loadConfig: function () {
    const me = this;

    me._super();

    const resourcesOptions = [
      {
        id: "position",
        icon: "position",
        prefKey: "locationActivated"
      },
      {
        id: "collectedData",
        icon: "data",
        useForDataConfig: true,
        useForShowData: true
      },
      {
        id: "alert",
        icon: "alert",
        dependsOn: ["position"],
        useForShowData: true
      },
      {
        id: "sensorMessage",
        icon: "speed",
        useForDataConfig: true
      },
      {
        id: "radar",
        rightKey: "radarProduct",
        icon: "vesselDetection"
      },
      {
        id: "sarAlert",
        icon: "alert",
        useForShowData: true
      },
      {
        id: "sarOperation",
        icon: "buoy",
        useForShowData: true
      }
    ];

    if (!RIGHTS("RCC")) {
      _.remove(resourcesOptions, { id: "sarAlert" });
    }

    me.attr("resourcesOptions", resourcesOptions);

    me.attr("dataConfig", [
      {
        id: "aisTraffic",
        icon: "radar",
        isVisible: () => APP().hasAisTraffic() && !APP().isAisTrafficModeForced(),
        prefKey: "aisTraffic"
      },
      {
        id: "collectedDatasAsPositions",
        icon: "data",
        isVisible: () => DATA_AUTHORIZED("collectedData"),
        prefKey: "collectedDatasAsPositions"
      },
      {
        id: "sensorMessagesAsPositions",
        icon: "speed",
        isVisible: () => DATA_AUTHORIZED("sensorMessage"),
        prefKey: "sensorMessagesAsPositions"
      }
    ]);
    me.attr("criterias", [
      DEFAULT_DATE_CRITERIA,
      DEFAULT_LAST_POSITION_CRITERIA,
      {
        centricParam: "centricCriteriaVessel",
        cmpClass: ThmSelectByCriteria,
        name: "selectBy",
        options: ["mobile", "fleet"]
      },
      DEFAULT_ZONE_CRITERIA,
      {
        centricParam: "centricCriteriaRadar",
        cmpClass: CheckboxesCriteria,
        i18nEmptyErrorKey: "_Toastr.noRadarProductStatus",
        name: "radarProduct",
        optionsByFunction: () => APP().getCapabilities('radarProduct', 'status') || [],
        resource: "radar",
        setOptionValue: (product, id) => {
          return {
            value: id,
            text: product
          }
        }
      },
      {
        centricParam: "centricCriteriaRadar",
        cmpClass: CheckboxesCriteria,
        i18nEmptyErrorKey: "_Toastr.noRadarEchoStatus",
        name: "radarEcho",
        optionsByFunction: () => APP().getCapabilities('radarEcho', 'status') || [],
        resource: "radar",
        setOptionValue: (echo, id) => {
          return {
            value: id,
            text: RESOURCE_CLASS["radarEcho"].getRadarEchoLabelByStatus(id)
          }
        }
      },
      DEFAULT_SPEED_CRITERIA,
      DEFAULT_NATURE_CRITERIA,
      DEFAULT_SOURCE_CRITERIA,
      {
        authorized: () => RIGHTS("data.positionsCriteria.priorityGPS") && !APP().hasAisTraffic(),
        centricParam: "centricCriteriaPriorityGPS",
        cmpClass: BooleanCriteria,
        labelKey: "thm.ui.umv.requestPage.ThmRequestPage.field.filterPriorityForGPS",
        name: "filterPriorityForGPS",
        resource: "position"
      },
      {
        cmpClass: BooleanCriteria,
        displaySetting: true,
        icon: "port",
        labelKey: "thm.ui.umv.requestPage.ThmRequestPage.field.excludePorts",
        name: "excludePorts",
        positionsCriteria: "excludePorts",
        resource: "position",
        settingLabel: "thm.ui.umv.requestPage.ThmRequestPage.excludePorts"
      },
      _.assign({
        resource: "alert",
        name: "status",
        optionsByFunction: (msg) => {
          let choices = APP().getCapabilities("alert", "parameters", "status").split(', '),
            result = {};
          _.forEach(choices, (choice) => {
            result[choice] = msg(choice);
          });
          return result;
        },
        setOptionValue: (status, id) => {
          return {
            value: id,
            text: status
          }
        }
      }, ALERT_UI.getUreCriteria().status),
      _.assign({
        resource: "alert",
        name: "kind"
      }, THM_ALERT_UI.getUreCriteria().kind),
      SAR_ALERT_STATUS_CRITERIA,
      SAR_ALERT_ACK_CRITERIA
    ]);

    me.attr("loadActions", [{
      id: "loadAllTrajectories",
      initValue: () => RIGHTS("loadAll") ? "all" : PREF("loadAllTrajectories"),
      isVisible: () => !APP().hasAisTraffic() && RIGHTS("afterSearch.showTrajectories"),
      getOptions: () => {
        return [
          {
            value: "last",
            text: I18N.msg("core-uda.ui.defaultRequest.DefaultRequestPage.trajectoryLast")
          },
          {
            value: "all",
            text: I18N.msg("core-uda.ui.defaultRequest.DefaultRequestPage.trajectoryAll")
          },
          {
            value: "visible",
            text: I18N.msg("core-uda.ui.defaultRequest.DefaultRequestPage.trajectoryVisible")
          }
        ];
      },
      stache: HcomboActionTpl
    },
    {
      id: "historicVesselDetails",
      active: false,
      border: true,
      tooltip: me.msg('detailsTooltip'),
      initValue: () => {
        return false;
      },
      isVisible: () => {
        return RIGHTS("data.positionsCriteria.historic");
      },
      stache: DeactivableCheckboxActionTpl
    },
    {
      id: "zoomAndCenter",
      border: true,
      initValue: () => !RIGHTS("afterSearch.zoomAndCenter") ? false : PREF("zoomAndCenter"),
      isVisible: () => RIGHTS("afterSearch.zoomAndCenter"),
      stache: CheckboxActionTpl
    }
    ]);
  },


  /**
   * Override
   */
  getCriteriaParams: function () {
    let me = this,
      criterias = me._super();

    if (criterias['position.nLastActivated']) {
      criterias['maxPositions'] = criterias['position.maxPositions'];
      criterias['lastPositions'] = criterias['position.lastPositions'];
      criterias['nLastActivated'] = criterias['position.nLastActivated'];
    }

    if (criterias['position.minSpeed']) {
      criterias['minSpeed'] = criterias['position.minSpeed'];
    }

    if (criterias['position.maxSpeed']) {
      criterias['maxSpeed'] = criterias['position.maxSpeed'];
    }

    if (criterias['position.source']) {
      criterias['source'] = criterias['position.source'];
    }

    if (criterias['position.nature']) {
      criterias['nature'] = criterias['position.nature'];
    }

    if (criterias['position.excludePorts']) {
      criterias['excludePorts'] = criterias['position.excludePorts'];
    }

    criterias['historicVesselDetails'] = me.viewModel.loadingFormModel.historicVesselDetails;

    // remove all criteria starting with "position."
    criterias = _.omitBy(criterias, (value, key) => _.startsWith(key, "position."));

    criterias['historicVesselDetails'] = me.viewModel.loadingFormModel.historicVesselDetails;

    return criterias;
  },

  applyParams: function (params) {
    let me = this;

    me._super(params);
    me.viewModel.attr('loadingFormModel').attr('historicVesselDetails', params.historicVesselDetails);
  },

  applyDefaultFavoriteRequest: function (value) {
    let me = this;

    me._super(value);
    me.viewModel.attr('loadingFormModel').attr('historicVesselDetails', value.historicVesselDetails);
  },

  /**
   * Override
   */
  validateParams: function () {
    const me = this;
    const criterias = this.getCriteriaParams();
    let valid = me._super();

    // au moins une dataOption (ex: position) doit être sélectionnée
    // (excpet for search and rescue)
    const { dataOptions } = me.viewModel.attr('settingsFormModel').attr();
    const noDataOptionSelected = Object.values(dataOptions).every(val => !val);
    if (noDataOptionSelected && !DATA_AUTHORIZED("searchAndRescue")) {
      Toastr.showError(me.msg('error.noDataOptionSelected'));
      valid = false;
    }

    // historicVesselDetails
    if (me.viewModel.loadingFormModel.historicVesselDetails) {
      if (typeof criterias['selectBy'] == 'undefined') {
        Toastr.showError(me.msg('error.historicVesselDetails.errorMobileNumber'), 5000);
        valid = false;
      } else {
        const mobiles = criterias['selectBy'].split(':')[1];
        if (!(
          criterias['selectBy'].indexOf("mobile") !== -1
          && mobiles.split(',').length > 0
          && mobiles.split(',').length < 6
        )) {
          Toastr.showError(me.msg('error.historicVesselDetails.errorMobileNumber'), 5000);
          valid = false;
        }
      }
    }

    return valid;
  },

  onApply: function () {
    let me = this;

    APP().trigger("mobileMapSelectionChange", ["clear"]);
    me._super();
  },

  /**
   * Override
   */
  getMenus: function () {
    let me = this,
      menus = me._super();

    //THM-10956 : Temporaire - en attendant l'ajout d'une gestion de visibilité sur les ressources (dans le panel Default Request)
    /*if(!APP().rights.role.data.alert.authorized || !RIGHTS('data.alertDefinition.authorized')) {
     menus.removeAttr('alert');
     }*/

    return menus;
  }
});

export default ThmRequestPage;
