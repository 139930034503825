import RenderingPage from "core-uda/ui/rendering/RenderingPage";
import { MAP_CONFIG } from "thm/ui/umv/ThmUmvMapConfig";
import { PREF } from "core-cmp/Preferences";
import GIS from "core-gis/Default";

/**
 * Custom rendering page for MDC
 */
let ThmRenderingPage = RenderingPage.extend({

  // override
  initViewModel: function () {
    let me = this;

    me._super();

    me.viewModel.attr('cmapCustomisable', RIGHTS('map.base.cmap.customisable'));
    me.viewModel.attr('tileSizes', ['256', '512', '1024']);

    me.viewModel.attr('dataFormModel').attr('cmapLoadMode', PREF("cmapLoadMode"));
  },

  /**
   *  Retunrs index of a sub layer inside layers array of a base layer
   *
   * @param layer
   * @param subLayerType
   * @returns {number}
   */
  getSubLayerIndexByType: function (layer, subLayerType) {
    let baseLayers = MAP_CONFIG.getBaseLayersConf(layer);

    return baseLayers.indexOf(subLayerType);
  },

  changeDataOptions: function (evt, attr, type, value, oldValue) {
    let me = this,
      toSave;

    if (me.updating) {
      return;
    }

    switch (attr) {
      case "cmapLoadMode":
        toSave = "cmapLoadMode";
        PREF(attr, value);
        if (RIGHTS('map.baseLayers', "").indexOf('marine') !== -1) {
          GIS.setFieldOnSubLayer("marine", me.getSubLayerIndexByType("marine", "tileWMS"), "visible", value === "tileWMS");
          GIS.setFieldOnSubLayer("marine", me.getSubLayerIndexByType("marine", "WMS"), "visible", value === "WMS");
          GIS.redrawLayer("marine");
          me.viewModel.attr("isTileWMS", value === "tileWMS");
        }
        break;
      case "baseLayer":
        toSave = me.baseLayerChange(value, evt.target);
        break;
      case "baseLayerWmsLayers":
        toSave = me.baseLayerWmsLayersChange(value);
        break;
      case "overlayLayers":
        me.overlayLayersChange(value);
        return;
      case "baseLayerWmsLayers":
        toSave = me.baseLayerWmsLayersChange(value);
        break;
      case "overlayLayers":
        me.overlayLayersChange(value);
        return;
      case "radarImageOptions":
        _.each(value.attr(), function (radarValue, radarKey) {
          let prefValue = PREF(radarKey);

          if (prefValue !== radarValue) {
            PREF(radarKey, radarValue);
            me.saveChanges(radarKey);
          }
        });
        return;
      case "wmsLayersOptions":
        _.each(value.attr(), function (overValue, overKey) {
          let prefKey = "wmsLayer-" + overKey,
            prefValue = PREF(prefKey);

          if (prefValue !== overValue) {
            PREF(prefKey, overValue);
            me.saveChanges(prefKey);
            GIS.setLayerVisible(overKey, overValue);
          }
        });
        return;
      case "radarLayersOptions":
        _.each(value.attr(), function (overValue, overKey) {
          let prefKey = overKey === "useUniqColorScene" ? overKey : "radarLayer-" + overKey,
            prefValue = PREF(prefKey);

          if (prefValue !== overValue) {
            PREF(prefKey, overValue);
            me.saveChanges(prefKey);

            APP().trigger('resetAllRadarProducts', [true]);
          }
        });
        return;
      case "trajectoryStyleV2":
      case "lastLabelPositionsValue":
      case "labelPositionsValue":
      case (attr.match(/^zoneStyle/) || {}).input:
      case (attr.match(/^radarProductStyle/) || {}).input:
      case "alertOn":
      case "alertAck":
      case "alertVal":
        toSave = attr;
        PREF(attr, value);
        break;
      case "breakRouteValue":
        me.saveBreakRoute(value);
        break;
      case "estimatedPositionsValue":
        me.saveEstimatedPositions(value);
        break;
      case "stationarySpeedValue":
        me.saveStationarySpeed(value);
        break;
      case "vesselRenderingOptions":
        toSave = _.findKey(value.attr(), (v, k) => {
          return (v !== oldValue[k]);
        });
        PREF(toSave, value.attr(toSave));
        break;
      case "flagsOnPosition":
        PREF(attr, value);
        toSave = attr;
        break;
      case "satRenderingValues":
        _.each(value.attr(), (val, key) => {
          PREF("satellite" + key.charAt(0).toUpperCase() + key.slice(1), val);
        });
        APP().trigger("redrawSatellites");
        PREF().saveList([
          "satelliteLabel",
          "satellitePath",
          "satelliteFootprint"
        ]);
        break;
      case "satDate":
        APP().trigger("satelliteAtDate", [value]);
        break;
      default:
        return;
    }

    if (toSave) {
      me.saveChanges(toSave);
    }
  }

});


export default ThmRenderingPage;
