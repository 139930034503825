<div id="{{id}}-image">
  <a id="{{id}}-image-a" class="image-view {{#unless imgSrc}}hidden{{/unless}}" href="{{imgSrc}}" data-lightbox="mobileImage">
    <img id="{{id}}-image" src="{{imgSrc}}" alt=""/>
  </a>
  <div id="{{id}}-image-delete"
       cls-action="deleteMobileImage"
       title="{{msg 'deleteImage'}}"
       class="btn ui-icon-delete btn-header-right btn-cancel {{#unless imgSrc}}hidden{{/unless}}"></div>
</div>
<div class="fields inlineGroup {{#unless editable}}hidden{{/unless}}" style="width: 75%;margin-left: 12.5%;">
  <field-file label="" {(field)}="image" accept-extentions="png,jpg,jpeg,svg" max-size="6Mo" class="l30-f70"></field-file>
</div>
